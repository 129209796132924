import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";

import NcwPermissionCard from "containers/newCompanyWizard/NcwPermissionCard";
import NcwGroupModal from "containers/newCompanyWizard/NcwGroupModal";
import { DialogComp } from "components/Dialog/DialogComp";
import { Checkbox, Button, Radio, FormHelperText, IconButton } from "@material-ui/core";
import { DeleteForever } from "@material-ui/icons";
import Cdo from "./../../services/Cdo";

const localization = {
    body: {
        emptyDataSourceMessage: "No hay datos por mostrar",
        addTooltip: "Añadir",
        deleteTooltip: "Eliminar",
        editTooltip: "Editar",
        filterRow: {
            filterTooltip: "Filtrar",
        },
        editRow: {
            saveTooltip: "Confirmar",
            cancelTooltip: "Cancelar",
            deleteText: "¿Desea eliminar el registro?",
        },
    },
    header: {
        actions: "Acciones",
    },
    pagination: {
        firstAriaLabel: "Primera página",
        firstTooltip: "Primera página",
        labelDisplayedRows: "{from}-{to} de {count}",
        labelRowsPerPage: "Filas por página:",
        labelRowsSelect: "filas",
        lastAriaLabel: "Ultima página",
        lastTooltip: "Ultima página",
        nextAriaLabel: "Pagina siguiente",
        nextTooltip: "Pagina siguiente",
        previousAriaLabel: "Pagina anterior",
        previousTooltip: "Pagina anterior",
    },
    toolbar: {
        exportAriaLabel: "Exportar",
        exportTitle: "Exportar",
        exportCSVName: "Exportar a CVS",
        exportPDFName: "Exportar a PDF",
        searchPlaceholder: "Buscar",
        searchTooltip: "Buscar",
    },

};


function esNumero(text) {
    if (text) {
        var reg = new RegExp("[0-9]+$");
        if (reg.test(text)) {
            return reg.test(text);
        } else {
            return false;
        }
    }
    return false;
}

const removerCaracteres = (event) => {
    let phone = event.target.value;
    if (phone) {
        let wordArray = phone.split("");
        let result = "";
        if (wordArray) {
            wordArray.map((item, i) => {
                if (esNumero(wordArray[i])) {
                    result += wordArray[i];
                }
            });
            return result;
        } else {
            return false;
        }
    }
    return false;
};


export default function NcwUserModal({ getter, setter, show, groups, setGroups, permissions, onDeleteGroup, onSave, definedGroups, pushDefinedGroup, setLoading, isLoading, customAssetsId }) {

    const regexPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\d)(?=.*[@$!%*;.,?&])[A-Za-z\d@$!%*?;.,&]{6,16}$/;

    const hSave = (e) => {
        if (e)
            e.preventDefault();

        if (error_msg && error_msg.length > 0)
            return;

        onSave(getter);
    }

    const hCancel = () => {
        setter({ ...getter, show: false, });
    }

    const doNewGroup = () => {
        setFormGroup({
            id: null,
            name: '',
            description: '',
            show: true,
        });
    };

    const onSaveGroup = async ({ id, name, description }) => {
        if (definedGroups && definedGroups.length > 0) {
            setLoading(true);
            try {
                const result = await Cdo.CdoAdmin.createGroup(name, description, customAssetsId);
                if (result === null)
                    throw new Error('Invalid response.');
                await Cdo.CdoAdmin.giveCompanyPermission(result.id);
                setLoading(false);
                const main_group_id = getter.main_group_id === null ? result.id : getter.main_group_id;
                setter({ ...getter, main_group_id, groups: [...getter.groups, result.id] });
                setGroups([...groups, { id: result.id, name, description }]);
                pushDefinedGroup(result);
                return;
            } catch (e) {
                console.error('ON_SAVE_GROUP_FAIL', e);
                setLoading(false);
                return;
            }
        }
        const idx = groups.findIndex(e => e.id === id);
        if (idx !== -1) {
            // Si el grupo ya existe, solo actualizar la propiedad del nombre.
            groups[idx].name = name;
            groups[idx].description = description;
            setGroups([...groups]);
        } else {
            // Garantizar que el Id del grupo sea unico y no exista en la DB.
            let $id = (groups.length > 0 ? groups[groups.length - 1].id : 1);
            if ($id > 0) {
                $id = ($id + 1) * -1;
            } else {
                $id--;
            }
            setGroups([...groups, { id: $id, name, description }]);
            // Por defecto, asociar el grupo al usuario que lo esta creando.
            if (getter.main_group_id === null)
                setter({ ...getter, main_group_id: $id, groups: [...getter.groups, $id] });
            else
                setter({ ...getter, groups: [...getter.groups, $id] });
        }
    }

    const [showAllGroups, setShowAllGroups] = useState(false);
    const [rand, setRand] = useState(new Date().getTime().toString());


    const [formGroup, setFormGroup] = useState({
        id: null,
        name: '',
        show: false,
    });

    const invalidPassword = getter.passwd.length > 0 && regexPass.test(getter.passwd) === false;
    const userExists = getter.id > 0;
    let $groups = [];
    if (showAllGroups) {
        $groups = groups;
    } else if (definedGroups && definedGroups.length > 0) {
        $groups = definedGroups;
    } else {
        $groups = groups.filter(e => e.id < 0);
    }

    let error_msg = '';
    if (!getter.first_name || getter.first_name.length <= 0)
        error_msg = 'El campo nombre es requerido.';
    else if (!getter.last_name || getter.last_name.length <= 0)
        error_msg = 'El campo apellido es requerido.';
    else if (getter.phone && getter.phone.length > 12)
        error_msg = 'El campo teléfono es inválido.';
    else if (!getter.email || getter.email.length < 1 || !getter.email.includes('@'))
        error_msg = 'El campo correo electrónico es requerido.';
    else if (getter.id <= 0 && (!getter.passwd || getter.passwd.length <= 0))
        error_msg = 'El campo contraseña es requerido.';
    else if (invalidPassword)
        error_msg = 'La contraseña no cumple las reglas requeridas.';
    else if (getter.passwd !== getter.confirm_passwd)
        error_msg = 'La contraseña es inválida, por favor verifique.';
    else if (getter.groups.length <= 0)
        error_msg = 'Debe asociar a este usuario al menos un grupo.';
    else if (getter.main_group_id === null || getter.main_group_id === 0)
        error_msg = 'Debe asociar un grupo principal a este usuario.';

    const content = !show ? (<div></div>) : (
        <div>
            <NcwGroupModal setter={setFormGroup} getter={formGroup} show={formGroup.show} onSave={onSaveGroup} />
            <form onSubmit={hSave}>
                <Grid container>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <b style={{ display: 'block', marginBottom: '0.5rem' }}>
                            Información personal del usuario
                        </b>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            label="Nombre"
                            name={rand + '-' + 'fxrst_nxme'}
                            variant="outlined"
                            value={getter.first_name}
                            onChange={(ev) => {
                                setter({ ...getter, first_name: ev.target.value });
                            }}
                            error={!getter.first_name || getter.first_name.length < 1}
                            required
                            margin="dense"
                            fullWidth
                            autoFocus
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            label="Apellido"
                            name={rand + '-' + 'lxst_nxme'}
                            variant="outlined"
                            value={getter.last_name}
                            onChange={(ev) => {
                                setter({ ...getter, last_name: ev.target.value });
                            }}
                            error={!getter.last_name || getter.last_name.length < 1}
                            required
                            margin="dense"
                            fullWidth
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <b style={{ display: 'block', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                            Datos de acceso
                        </b>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            label="Teléfono"
                            name={rand + '-' + 'phxne'}
                            variant="outlined"
                            value={getter.phone}
                            onChange={(ev) => {
                                setter({ ...getter, phone: removerCaracteres(ev) });
                            }}
                            error={getter.phone && getter.phone.length > 12}
                            margin="dense"
                            fullWidth
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <TextField

                            label="Correo electrónico"
                            type="text"
                            name={rand + '-' + 'emxil'}
                            variant="outlined"
                            value={getter.email}
                            onChange={(ev) => {
                                setter({ ...getter, email: ev.target.value });
                            }}
                            error={!getter.email || getter.email.length < 1 || !getter.email.includes('@')}
                            required
                            margin="dense"
                            fullWidth
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            type="password"
                            name={rand + '-' + 'pxsswd'}
                            label="Contraseña"
                            variant="outlined"
                            value={getter.passwd}
                            onChange={(ev) => {
                                setter({ ...getter, passwd: ev.target.value });
                            }}
                            error={userExists ? getter.passwd.length > 0 && invalidPassword : (!getter.passwd || getter.passwd.length < 1 || invalidPassword)}
                            required={!userExists || getter.passwd.length > 0 || getter.confirm_passwd.length > 0}
                            margin="dense"
                            fullWidth
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            name={rand + '-' + 'cxnfirm_pxsswd'}
                            type="password"
                            label="Confirmar contraseña"
                            variant="outlined"
                            value={getter.confirm_passwd}
                            onChange={(ev) => {
                                setter({ ...getter, confirm_passwd: ev.target.value });
                            }}
                            error={userExists ? getter.confirm_passwd.length > 0 && invalidPassword : (!getter.confirm_passwd || getter.confirm_passwd.length < 1 || invalidPassword) || getter.passwd !== getter.confirm_passwd}
                            required={!userExists || getter.passwd.length > 0 || getter.confirm_passwd.length > 0}
                            margin="dense"
                            fullWidth
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <small style={{ display: 'block', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                            La contraseña debe tener de 6 a 16 caracteres y contener una mayúscula, una minúscula y uno o mas números.
                        </small>
                    </GridItem>
                </Grid>
                <input type="submit" style={{ display: 'none' }} />
            </form>
            <Grid container>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <FormHelperText style={{ color: 'red' }}>{getter.confirm_passwd && getter.passwd && getter.passwd !== getter.confirm_passwd ? 'La contraseña no es igual a la confirmación, por favor verifique.' : null}</FormHelperText>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <b style={{ display: 'block', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                        Rol de usuario
                    </b>
                </GridItem>
                {Object.values(permissions).map(v => {
                    return <GridItem xs={12} sm={12} md={4} lg={4}>
                        <NcwPermissionCard
                            data={v}
                            selected={getter.role_id === v.id}
                            onClick={async () => {
                                if (userExists) {
                                    try {
                                        setLoading(true);
                                        const result = await Cdo.CdoAdmin.assignRole(getter.id, v.id);
                                        setLoading(false);
                                        if (!result)
                                            return;
                                    } catch (e) {
                                        setLoading(false);
                                        return;
                                    }
                                }
                                setter({ ...getter, role_id: v.id });
                            }} />
                    </GridItem>
                })}
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div>
                            <b style={{ display: 'block', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                Grupos
                            </b>
                            <small>Advertencia: El nivel de acceso a los documentos es determinado según los
                                grupos a los que pertenezca el usuario. Si el usuario pertenece a distintos
                                grupos pero no tiene rol de <b>Supervisor</b>, no podrá tener acceso a los documentos de los grupos secundarios que se le haya asignado.</small>
                        </div>
                        <div>
                            <Button
                                color="primary"
                                variant="contained"
                                type="button"
                                onClick={doNewGroup}
                            >
                                Nuevo
                            </Button>
                        </div>
                    </div>
                    <small>
                        <a href="#" onClick={() => setShowAllGroups(!showAllGroups)}>
                            {showAllGroups ?
                                'Para ocultar todos los grupos, haga clic aquí.' :
                                '¿Desea ver todos los grupos? Haga clic aquí.'
                            }
                        </a>
                    </small>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <MaterialTable
                        localization={localization}
                        options={{
                            draggable: false,
                            actionsColumnIndex: -1,
                            exportButton: false,
                            search: true,
                            pageSize: 10, // make initial page size
                            pageSizeOptions: [10, 50, 100, 500, 1000],
                        }}
                        columns={[
                            {
                                title: "¿Es grupo principal?",
                                field: "is_main",
                                render: (row) => {
                                    return (<Radio
                                        name="is_main_group"
                                        value={row.id}
                                        checked={getter.main_group_id === row.id}
                                        onChange={async (ev) => {
                                            if (userExists) {
                                                try {
                                                    setLoading(true);
                                                    const result = await Cdo.CdoAdmin.assignRole(getter.id, row.id, true);
                                                    setLoading(false);
                                                    if (!result)
                                                        return;
                                                } catch (e) {
                                                    setLoading(false);
                                                    return;
                                                }
                                            }
                                            const groups = [...getter.groups];
                                            // Si el grupo no esta asociado, asociarlo al hacerlo principal
                                            const asociado = getter.groups.includes(row.id);
                                            if (!asociado)
                                                groups.push(row.id);
                                            setter({ ...getter, groups, main_group_id: row.id });
                                        }}
                                    />)
                                },
                            },
                            {
                                title: "Asociado",
                                field: "is_selected",
                                render: (row) => {
                                    return (<Checkbox
                                        checked={getter.groups.includes(row.id)}
                                        disabled={userExists && row.id == getter.groups[0]}
                                        onChange={async (ev) => {
                                            const doAssign = ev.target.checked == 'true' || ev.target.checked === true;
                                            const main_group_id = getter.main_group_id === null ? row.id : getter.main_group_id;
                                            if (userExists) {
                                                try {
                                                    setLoading(true);
                                                    const result = doAssign ?
                                                        await Cdo.CdoAdmin.assignRole(getter.id, row.id, row.id === main_group_id) :
                                                        await Cdo.CdoAdmin.removeRole(getter.id, row.id);
                                                    setLoading(false);
                                                    if (!result)
                                                        return;
                                                } catch (e) {
                                                    setLoading(false);
                                                    return;
                                                }
                                            }
                                            const groups = [...getter.groups];
                                            if (doAssign) {
                                                setter({ ...getter, groups: [...groups, row.id], main_group_id });
                                            } else {
                                                const idx = getter.groups.indexOf(row.id);
                                                groups.splice(idx, 1);
                                                setter({ ...getter, groups });
                                            }
                                        }}
                                    />)
                                },
                            },
                            { title: "Nombre", field: "name" },
                            { title: "Descripción", field: "description" },
                            {
                                title: "",
                                render: (row) => {

                                    return <IconButton
                                        color="primary"
                                        onClick={async () => {
                                            console.log({ definedGroups, row });
                                            if (definedGroups.find(e => e.id == row.id)) {
                                                if (!window.confirm('Está a punto de eliminar este grupo. Esta acción será irrevocable. ¿Desea continuar?'))
                                                    return;

                                                setLoading(true);
                                                let result = false;
                                                try {
                                                    result = await Cdo.CdoAdmin.deleteRole(row.id);
                                                } catch (e) {
                                                    console.error('EX_DELETE_GROUP', e);
                                                }
                                                setLoading(false);

                                                if (!result)
                                                    return;
                                            }
                                            // Remover grupo de asociados del usuario.
                                            const userGroups = [...getter.groups];
                                            const groupIdx = getter.groups.indexOf(row.id);
                                            if (groupIdx !== -1) {
                                                userGroups.splice(groupIdx, 1);
                                                setter({ ...getter, groups: userGroups });
                                            }
                                            onDeleteGroup(row.id)
                                        }}
                                    >
                                        <DeleteForever />
                                    </IconButton>
                                }
                            }
                        ]}
                        title=""
                        data={$groups}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <FormHelperText style={{ color: 'red', fontWeight: 'bold' }}>{error_msg}</FormHelperText>
                </GridItem>
            </Grid>
        </div>);

    return (
        <DialogComp open={show && !isLoading}
            title={"Crear nuevo usuario"}
            content={content}
            fullWidth={"md"}
            maxWidth={"md"}
            buttons={[
                {
                    buttonEvent: hCancel,
                    buttonName: userExists ? "Cerrar" : "Cancelar",
                    buttonColor: "secondary",
                },
                {
                    buttonEvent: hSave,
                    buttonName: "Guardar",
                    buttonColor: "primary",
                    buttonDisabled: error_msg && error_msg.length > 0
                },
            ]} />
    );
}