import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Snackbar from "@material-ui/core/Snackbar";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ImagePicker from "components/ImagePicker";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { green } from "@material-ui/core/colors";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AdminRoutes } from "routes";
import MaterialTable from "material-table";
import Cdo from "./../../services/Cdo";
import { useHistory, useParams } from "react-router-dom";
import IMaterialTable from "./../../components/IMaterialTable";

import NcwModuleCard from "containers/newCompanyWizard/NcwModuleCard";
import NcwUserModal from "containers/newCompanyWizard/NcwUserModal";

import styles2 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import { group } from "d3";
import { Assessment, Description, Domain, NoteAdd } from "@material-ui/icons";

const useStylestable = makeStyles(styles2);

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#04B9F3",
    },
    root2: {
        flexGrow: 1,
    },
    label: {
        textTransform: "capitalize",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    buttonProgress2: {
        color: green[500],
        alignItems: "center",
        top: "50%",
        left: "50%",
        marginTop: 12,
        marginLeft: 12,
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

export default function NewCompanyView(props) {
    const history = useHistory();
    const classes2 = useStylestable();
    const classes = useStyles();
    const { customAssetsId } = useParams();
    const [successAlert, setSuccessAlert] = useState(false);
    const [grupos, setGrupos] = useState([]);
    const [permisos, setPermisos] = useState({
        observador: {
            id: 'observador',
            title: 'Observador',
            description: 'El usuario solo puede ver su propia información y no crear o modificar.',
            icon: Description,
        },
        creador: {
            id: 'creador',
            title: 'Creador',
            description: 'El usuario solamente puede ver su propia información y crear o modificar.',
            icon: NoteAdd,
        },
        supervisor: {
            id: 'supervisor',
            title: 'Supervisor',
            description: 'El usuario puede ver su propia información y con los grupos de trabajo que comparta. También ver estadísticas y crear o modificar.',
            icon: Assessment,
        },
    });
    const [isLoading, setIsLoading] = useState(true);

    const [form, setForm] = useState({
        id: null,
        logo: null,
        companyName: '',
        companySlug: '',
        corporations: [],
        users: [],
        groups: [],
    });

    useEffect(() => {
        (async () => {
            const respGrupos = await Cdo.CdoAdmin.obtenerGrupos();
            // filtrar grupos, no mostrar los generales o de permisos.
            const gruposGenerales = ['general', 'websuperadmin.', 'front', 'supervisor', 'creador', 'observador', 'administradorcdo'];
            const grupos = [];
            for (const grupo of respGrupos) {
                // buscar y determinar si el grupo es general o no
                const grupoName = grupo.name.toLowerCase();
                const idx = gruposGenerales.indexOf(grupoName);
                if (idx !== -1) {
                    continue;
                }
                grupos.push(grupo);
            }

            try {
                const hasCustomAssets = typeof customAssetsId === 'string' && customAssetsId.length > 0 && customAssetsId !== 'new';
                if (hasCustomAssets) {
                    const companyInfo = await Cdo.CdoAdmin.fetchCompanyByCustomAssetsId(customAssetsId);
                    if (!companyInfo) {
                        alert('No se ha encontrado la compañía indicada.');
                        throw new Error('CUSTOM_ASSETS_404');
                    }
                    const companyFrm = {
                        id: companyInfo._id,
                        logo: null,
                        companyName: companyInfo.company,
                        companySlug: companyInfo.uri,
                        corporations: [],
                        users: [],
                        groups: companyInfo.groups,
                    };
                    // Si tiene logo, crear blob y asignar.
                    if (typeof companyInfo.logo === 'string' && companyInfo.logo.length > 0) {
                        companyFrm.logo = URL.createObjectURL(await (await fetch(`data:image/png;base64,${companyInfo.logo}`)).blob());
                    }
                    companyFrm.corporations = companyInfo.corporations.map((e) => {
                        return { id: e._id, name: e.name, tableData: { id: e._id } }
                    });

                    companyFrm.users = companyInfo.users.map((user) => {
                        const { id, first_name, last_name, email, company, city, phone, group_id, groups_id, role_id } = user;
                        return {
                            id,
                            first_name,
                            last_name,
                            passwd: '',
                            confirm_passwd: '',
                            email,
                            company,
                            city,
                            phone,
                            show: false,
                            groups: groups_id,
                            main_group_id: group_id,
                            role_id,
                        }
                    })
                    await setForm(companyFrm);
                }
            } catch (e) {
                console.error('ERROR_FETCH_CUSTOM_ASSETS', e);
            }

            setIsLoading(false);
            setGrupos(grupos);
        })();
    }, []);

    const [formUser, setFormUser] = useState({
        id: null,
        first_name: '',
        last_name: '',
        passwd: '',
        confirm_passwd: '',
        email: '',
        company: '',
        city: '',
        phone: '',
        show: false,
        groups: [],
        main_group_id: null,
        role_id: 'creador',
    });

    const handleChangeValues = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    };

    const doNewUser = () => {
        // Garantizar que el Id del grupo sea unico y no exista en la DB.
        let $id = (form.users.length > 0 ? form.users[form.users.length - 1].id : 1);
        if ($id > 0) {
            $id = ($id + 1) * -1;
        } else {
            $id--;
        }
        setFormUser({
            id: $id,
            first_name: '',
            last_name: '',
            passwd: '',
            confirm_passwd: '',
            email: '',
            company: '',
            city: '',
            phone: '',
            show: true,
            groups: [],
            main_group_id: null,
            role_id: 'creador',
        })
    };

    const modules = AdminRoutes.filter(e => e.isModule && e.isModule === true);
    const doUpdate = form.id !== null && form.id.length > 0;

    const localization = {
        body: {
            emptyDataSourceMessage: "No hay datos por mostrar",
            addTooltip: "Añadir",
            deleteTooltip: "Eliminar",
            editTooltip: "Editar",
            filterRow: {
                filterTooltip: "Filtrar",
            },
            editRow: {
                saveTooltip: "Confirmar",
                cancelTooltip: "Cancelar",
                deleteText: "¿Desea eliminar el registro?",
            },
        },
        header: {
            actions: "Acciones",
        },
        pagination: {
            firstAriaLabel: "Primera página",
            firstTooltip: "Primera página",
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsPerPage: "Filas por página:",
            labelRowsSelect: "filas",
            lastAriaLabel: "Ultima página",
            lastTooltip: "Ultima página",
            nextAriaLabel: "Pagina siguiente",
            nextTooltip: "Pagina siguiente",
            previousAriaLabel: "Pagina anterior",
            previousTooltip: "Pagina anterior",
        },
        toolbar: {
            exportAriaLabel: "Exportar",
            exportTitle: "Exportar",
            exportCSVName: "Exportar a CVS",
            exportPDFName: "Exportar a PDF",
            searchPlaceholder: "Buscar",
            searchTooltip: "Buscar",
        },

    };

    const onDeleteGroup = (id) => {
        // Remover grupo de asociados a cada usuario.
        const users = [];
        for (const user of form.users) {
            const userGroups = [...user.groups];
            const groupIdx = user.groups.indexOf(id);
            if (groupIdx !== -1) {
                userGroups.splice(groupIdx, 1);
            }
            users.push(user);
        }
        setForm({ ...form, users });
        // Remover grupo completamente de la lista
        const $groups = [...grupos];
        const idx = $groups.findIndex(e => e.id === id);
        if (idx !== -1) {
            $groups.splice(idx, 1);
            setGrupos($groups);
        }

        const $definedGroups = [...form.groups];
        const dgIdx = $definedGroups.findIndex(e => e.id == id);
        if (dgIdx !== -1) {
            $definedGroups.splice(dgIdx, 1);
            setForm({ ...form, groups: $definedGroups });
        }
    }

    const onSaveUser = async (user) => {
        // Validar que el correo se encuentre disponible.
        setIsLoading(true);

        // mapear nombre de la compañia
        user.company = form.companyName;
        user.city = form.companyName;
        user.phone = '1111111111';

        setFormUser({ ...formUser, show: false });
        try {
            if (user.id <= 0) {
                const emailAvailable = await Cdo.CdoAdmin.isEmailAvailable(user.email);
                if (!emailAvailable) {
                    alert(`El correo "${user.email}" ya se encuentra en uso, utilice otro.`);
                    throw "Email is not available.";
                }
            }
            if (doUpdate) {
                if (user.id > 0) {
                    await Cdo.CdoAdmin.updateUser(user.id, user.email, user.first_name, user.last_name, user.passwd, user.phone, user.company, user.city);
                } else {
                    // El usuario se va crear de nuevo
                    const userId = await Cdo.CdoAdmin.createUser(user.email, user.first_name, user.last_name, user.passwd, user.phone, user.company, user.city);
                    user.id = userId;
                    // asginar rol principal (observador, creador, supervisor)
                    await Cdo.CdoAdmin.assignRole(userId, user.role_id);
                    // asignar grupo principal que asocia el usuario con la compañia
                    await Cdo.CdoAdmin.assignRole(userId, form.groups[0].id)
                    // asignar grupos en backend.
                    for (const groupId of user.groups) {
                        await Cdo.CdoAdmin.assignRole(userId, groupId, user.main_group_id == groupId);
                    }
                }
            }
            const users = [...form.users];
            const idx = form.users.findIndex(e => e.id === user.id);
            if (idx === -1) {
                users.push(user);
            } else {
                users[idx] = user;
            }
            setForm({ ...form, users, });
        } catch (e) {
            setFormUser({ ...formUser, show: true });
            console.log('Error al guardar!', e);
        } finally {
            setIsLoading(false);
        }
    };

    const isError = !form.companyName || form.companyName.length <= 0 || form.users.length <= 0;

    const crearCliente = async () => {
        if (isLoading || isError)
            return;

        setIsLoading(true);
        const request = new FormData();
        const frm = {
            ...form,
            groups: grupos.filter(e => e.id <= 0),
        };
        // mapear nombre de la compañia en todos los usuarios, si se esta creando
        for (const user of frm.users) {
            user.companyName = frm.companyName;
            user.city = frm.companyName;
            user.phone = '1111111111';
        }
        if (frm.logo && frm.logo.length > 0 && frm.logo.includes('blob:')) {
            const logo = await fetch(frm.logo).then(r => r.blob());
            request.append("logo", logo);
        }
        request.append("request", JSON.stringify(frm));
        try {
            const response = doUpdate ? await Cdo.CdoAdmin.updateCustomer(request) : await Cdo.CdoAdmin.createCustomer(request);
            if (response.message)
                throw response.message;
            if (response.request && response.request[0])
                throw response.request[0];
            if (response.ok !== true)
                throw "Invalid server response.";
            setSuccessAlert(true);
            await new Promise((solve) => setTimeout(solve, 3000));
            setSuccessAlert(false);
            // redireccionar a la ruta anterior
            history.replace('/admin/registrerUser');
        } catch (e) {
            alert(e);
        } finally {
            setIsLoading(false);
        }
    }

    const handlerCorporaciones = {
        async add(name) {
            try {
                setIsLoading(true);
                const result = await Cdo.CdoAdmin.createCorporacion(form.groups[0].id, name);
                if (!result || !result._id)
                    throw new Error('Expecting to get a valid result.');
                setIsLoading(false);
                return result._id;
            } catch (e) {
                console.error('HANDLER_CORPORACIONES_ADD_EX', e);
            } finally {
                setIsLoading(false);
            }
            return null;
        },
        async delete(id) {
            try {
                setIsLoading(true);
                const result = await Cdo.CdoAdmin.deleteCorporacion(id);
                if (result !== true)
                    throw new Error('Expecting to get a valid result.');
                setIsLoading(false);
                return true;
            } catch (e) {
                console.error('HANDLER_CORPORACIONES_DELETE_EX', e);
            } finally {
                setIsLoading(false);
            }
            return false;
        },
        async update(id, name) {
            try {
                setIsLoading(true);
                const result = await Cdo.CdoAdmin.updateCorporacion(id, name);
                if (result !== true)
                    throw new Error('Expecting to get a valid result.');
                setIsLoading(false);
                return true;
            } catch (e) {
                console.error('HANDLER_CORPORACIONES_UPDATE_EX', e);
            } finally {
                setIsLoading(false);
            }
            return false;
        },
    }

    const handlerUsuarios = {
        async delete(id) {
            try {
                setIsLoading(true);
                const result = await Cdo.CdoAdmin.deleteUsuario(id);
                if (result !== true)
                    throw new Error('Expecting to get a valid result.');
                setIsLoading(false);
                return true;
            } catch (e) {
                console.error('HANDLER_USUARIOS_DELETE_EX', e);
            } finally {
                setIsLoading(false);
            }
            return false;
        },
    }

    return (
        <div>
            <Snackbar
                open={successAlert}
                autoHideDuration={4000}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Alert elevation={6} variant="filled" severity="success">
                    {doUpdate ? 'Se han guardado los cambios exitosamente.' : 'Se ha registrado el cliente exitosamente.'}
                </Alert>
            </Snackbar>
            <NcwUserModal
                setter={(e) => {
                    setFormUser(e);
                    if (e.id > 0) {
                        const user = form.users.find(u => u.id == e.id);
                        user.role_id = e.role_id;
                        user.groups = e.groups;
                        setForm({ ...form });
                    }
                }}
                getter={formUser}
                isLoading={isLoading}
                customAssetsId={form.id}
                pushDefinedGroup={(group) => setForm({ ...form, groups: [...form.groups, group] })}
                setLoading={setIsLoading}
                show={formUser.show}
                groups={grupos}
                definedGroups={form.groups}
                setGroups={setGrupos}
                permissions={permisos}
                onDeleteGroup={onDeleteGroup}
                onSave={onSaveUser}
            />
            <Grid container>
                <GridItem xs={12} sm={12} md={12} lg={6}>

                    <GridItem xs={12}>
                        <Button
                            size="large"
                            style={{ marginTop: "-9px" }}
                            startIcon={<ArrowBackIcon />}
                            onClick={history.goBack}
                        >
                            Regresar
                        </Button>
                        <h4>Información general del cliente</h4>
                        <p style={{ opacity: 0 }}>-</p>
                    </GridItem>
                    <GridItem xs={12}>
                        <Card style={{ paddingTop: "1rem", paddingBottom: "1rem", marginTop: "0px" }}>
                            <Grid container>
                                <GridItem xs={12}>
                                    <ImagePicker
                                        title="Logo"
                                        image={form.logo}
                                        placeholder="Puede establecer un logo de la compañía en este campo, por defecto se muestra Con-Certeza."
                                        defaultImage="/static/media/ConCertezaGris.682e812c.png"
                                        onPick={(logo) => setForm({ ...form, logo })}
                                        style={{ marginBottom: "1rem" }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={8}>
                                    <TextField
                                        id="standard-name"
                                        label="Nombre de la compañía o cliente"
                                        variant="filled"
                                        name="companyName"
                                        value={form.companyName}
                                        error={!form.companyName || form.companyName.length <= 0}
                                        margin="dense"
                                        fullWidth
                                        onChange={(event) => handleChangeValues(event)}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        id="standard-name"
                                        label="Slug"
                                        variant="filled"
                                        name="companySlug"
                                        value={form.companySlug}
                                        margin="dense"
                                        fullWidth
                                        onChange={(event) => handleChangeValues(event)}
                                    />
                                </GridItem>
                                <GridItem xs={12}>
                                    <b style={{ color: 'red', fontWeight: 'bold', display: !form.companyName || form.companyName.length < 3 ? 'block' : 'none' }}>Debe escribir un nombre de compañía válido para continuar.</b>
                                </GridItem>
                            </Grid>
                        </Card>
                    </GridItem>
                    <div style={{ paddingTop: "1.5rem", paddingBottom: "1rem", marginTop: "0px" }}>
                        <Grid container>
                            <GridItem xs={12}>
                                <p>Las corporaciones pueden ser vistas como múltiples compañías del mismo cliente, son utilizadas dentro del sistema para cambiar el remitente en cada proceso.</p>
                                <MaterialTable
                                    localization={localization}
                                    editable={{
                                        onRowAdd: newData =>
                                            new Promise(async (resolve, reject) => {
                                                newData.id = doUpdate ? await handlerCorporaciones.add(newData.name) : null;
                                                setForm({ ...form, corporations: [...form.corporations, newData] });
                                                resolve();
                                            }),
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(async (resolve, reject) => {
                                                if (doUpdate) {
                                                    const result = await handlerCorporaciones.update(oldData.id, newData.name);
                                                    if (!result) {
                                                        reject();
                                                        return;
                                                    }
                                                }
                                                const idx = oldData.tableData.id;
                                                const corporations = [...form.corporations];
                                                corporations[idx] = newData;
                                                setForm({ ...form, corporations });
                                                resolve();
                                            }),
                                        onRowDelete: oldData =>
                                            new Promise(async (resolve, reject) => {
                                                if (doUpdate) {
                                                    if (!window.confirm('Está a punto de eliminar la corporación. Esta acción será irrevocable. ¿Desea continuar?')) {
                                                        reject();
                                                        return;
                                                    }
                                                    const result = await handlerCorporaciones.delete(oldData.id);
                                                    if (!result) {
                                                        reject();
                                                        return;
                                                    }
                                                }
                                                const idx = oldData.tableData.id;
                                                const corporations = [...form.corporations];
                                                corporations.splice(idx, 1);
                                                setForm({ ...form, corporations });
                                                resolve();
                                            }),
                                    }}
                                    options={{
                                        draggable: false,
                                        toolbar: true,
                                        actionsColumnIndex: -1,
                                        exportButton: false,
                                        search: true,
                                        pageSize: 10, // make initial page size
                                        pageSizeOptions: [10, 50, 100, 500, 1000],
                                        toolbarButtonAlignment: 'left',
                                    }}
                                    columns={[
                                        { title: "Id", field: "id", hidden: true, },
                                        { title: "Nombre", field: "name" },
                                    ]}
                                    title=""
                                    data={form.corporations}
                                />
                            </GridItem>
                        </Grid>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={6}>
                    <Grid container>
                        <GridItem xs={12} sm={12} md={12} lg={8}>
                            <h4>Usuarios</h4>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={4} style={{ textAlign: "right" }}>
                            <Button
                                disabled={!form.companyName || form.companyName.length < 3}
                                color="primary"
                                variant="contained"
                                onClick={doNewUser}
                            >
                                Nuevo
                            </Button>
                        </GridItem>
                        <GridItem xs={12}>
                            <p>Registre los usuarios finales que utilizarán el sistema de Con-Certeza, por esta compañía.</p>
                            <MaterialTable
                                localization={localization}
                                options={{
                                    draggable: false,
                                    toolbar: false,
                                    actionsColumnIndex: -1,
                                    exportButton: false,
                                    search: false,
                                    pageSize: 20, // make initial page size
                                    pageSizeOptions: [20, 50, 100, 500, 1000],
                                }}
                                columns={[
                                    {
                                        title: "Nombre",
                                        field: "first_name",
                                        render: (row) => {
                                            return (<span>
                                                {row.first_name} {row.last_name}
                                            </span>)
                                        }
                                    },
                                    {
                                        title: "Rol",
                                        field: "role_id",
                                    },
                                    {
                                        title: "Grupos",
                                        field: "groups",
                                        render: (row) => {
                                            const $grupos = [];
                                            for (const id of row.groups) {
                                                const grupo = grupos.find(e => e.id == id);
                                                if (!grupo)
                                                    continue;
                                                $grupos.push(grupo.name);
                                            }
                                            return <span>{$grupos.join(', ')}</span>
                                        }
                                    },
                                ]}
                                actions={[
                                    {
                                        icon: () => (
                                            <EditIcon
                                                className={
                                                    classes2.tableActionButtonIcon + " " + classes2.edit
                                                }
                                            />
                                        ),
                                        tooltip: "Editar usuario",
                                        onClick: (event, rowData) => {
                                            setFormUser({
                                                ...rowData,
                                                show: true,
                                            })
                                        },
                                    },
                                    {
                                        icon: () => (
                                            <DeleteIcon
                                                className={
                                                    classes2.tableActionButtonIcon + " " + classes2.edit
                                                }
                                            />
                                        ),
                                        tooltip: "Eliminar usuario",
                                        onClick: async (event, rowData) => {
                                            if (doUpdate) {
                                                if (!window.confirm('Está a punto de eliminar el usuario. Esta acción será irrevocable. ¿Desea continuar?'))
                                                    return;
                                                const result = await handlerUsuarios.delete(rowData.id);
                                                if (!result) {
                                                    return;
                                                }
                                            }
                                            const users = [...form.users];
                                            const idx = users.findIndex(e => e.id === rowData.id);
                                            if (idx === -1) {
                                                return;
                                            }
                                            users.splice(idx, 1);
                                            setForm({ ...form, users });
                                        },
                                    },
                                ]}
                                title=""
                                data={form.users}
                            />
                        </GridItem>
                    </Grid>
                    <div style={{ display: 'block', marginTop: '1rem', width: '100%', textAlign: 'right' }}>
                        <Button
                            color={doUpdate ? 'primary' : 'secondary'}
                            variant="contained"
                            onClick={crearCliente}
                            disabled={isLoading || isError}
                        >
                            {doUpdate ? 'Guardar cambios' : 'Crear nuevo cliente'}
                        </Button>
                    </div>
                </GridItem>
            </Grid>
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress size={44} className={classes.buttonProgress} />
            </Backdrop>
        </div>
    )
}