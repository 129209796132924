import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import validator from "validator";
import ButtonC from "components/CustomButtons/Button.js";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { green } from "@material-ui/core/colors";

import styles from "assets/jss/material-kit-react/views/components.js";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(styles);
const useStylesprogrees = makeStyles((theme) => ({
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    buttonProgress2: {
        color: green[500],
        alignItems: "center",
        top: "50%",
        left: "50%",
        marginTop: 12,
        marginLeft: 12,
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

export default function CompartirExpediente(props) {
    const classes = useStyles();
    const classespro = useStylesprogrees();
    const { idExpediente, setModalCompartir } = props
    const [textoValidacionMail, setTextoValidacionMail] = useState("");
    const [datos, setDatos] = useState({
        contacto: "",
        correo: "",
    });
    const [disabledContact, setDisabledContact] = useState(true);
    const [progress, setprogress] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const handleCloseGuardado = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnack(false);
    };
    
    

    function validarMail(email) {
        let tokenSG =
            "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";
        var config = {
            async: true,
            crossDomain: true,
            method: "post",
            url: `https://api.sendgrid.com/v3/validations/email`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + tokenSG,
            },
            processData: false,
            data: `{\"email\":\"${email}\",\"source\":\"signup\"}`,
        };
        axios(config)
            .then(function (response) {
                console.log("Email Validation:", response);
                if (response.data.result.verdict === "Invalid") {
                    setTextoValidacionMail("Correo no válido");
                } else {
                    setTextoValidacionMail("");
                }
                //verdict: "Invalid"
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    
    const handleinput = (event) => {
        setDatos({
            ...datos,
            [event.target.name]: event.target.value,
        });
    }

    
    useEffect(() => {
        let esCorreo = validator.isEmail(datos.correo);
        if (esCorreo && datos.contacto.length >= 3) {
            setDisabledContact(false)
        }

    }, [datos]);

    const sendEnlace = () => {
        let token = sessionStorage.getItem("Token");
        setprogress(true)
        let url = `${process.env.REACT_APP_URL}api/expedientes/share/${idExpediente}`;
        const data = new FormData();
        data.append("name", datos.contacto);
        data.append("email", datos.correo)
        axios
            .post(url, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                withCredentials: true,
                crossDomain: true,
            })
            .then((response) => {
                console.log("Compartir ", response);
                setOpenSnack(true);
                setTimeout(function () {
                    setprogress(false)
                    setModalCompartir(false)
                }, 2000);
                setDatos({
                contacto: "",
                correo: "",
                });
            })
            .catch((error) => {
                console.log(error)
                alert("Error al Compartir Expediente")
                setprogress(false)

            });
    }

    return(
        <>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="contacto"
                        name="contacto"
                        variant="outlined"
                        label="Nombre de contacto"
                        value={datos.contacto}
                        fullWidth
                        autoComplete="given-name"
                        onChange={handleinput}
                        inputProps={{ maxLength: 80 }}
                        error={datos.contacto.length > 1 ? false : true}
                    />
            </Grid>
            <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="correo"
                        name="correo"
                        variant="outlined"
                        label="Correo electrónico"
                        value={datos.correo}
                        fullWidth
                        autoComplete="correo"
                        onChange={handleinput}
                        inputProps={{ maxLength: 60 }}
                        error={datos.correo > 5 ? false : true}
                        onBlur={() => {
                            if (datos.correo) {
                                validarMail(datos.correo);
                            }
                        }}
                    />
                    <font color="red">{textoValidacionMail}</font>
                </Grid>
                <Grid item xs={6} sm={12} style={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
                >
                    <ButtonC

                        color="success"
                        onClick={sendEnlace}
                        disabled={disabledContact}
                    >
                        Compartir
                    </ButtonC>
                </Grid>
        </Grid>
            <Snackbar
            open={openSnack}
            autoHideDuration={4000}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            onClose={handleCloseGuardado}
        >
            <Alert onClose={handleCloseGuardado} severity="success">
                El enlace de descarga del expediente ha sido enviado.
            </Alert>
        </Snackbar>
        <Backdrop className={classespro.backdrop} open={progress}>
            <CircularProgress size={44} className={classespro.buttonProgress} />
        </Backdrop>
        </>
    )
}